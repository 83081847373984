import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TagPostsWrapper, TagPageHeading, TagName, SectionTitle } from './templates.style';
import PostCardList from '../components/post-card-list/post-card-list';
import ImageCardList from '../components/image-card-list/image-card-list';

const Tag = ({ pageContext, data }: any) => {
  const tag = pageContext.tag.replace(/\//g, '');
  const wishes = data.wishes.edges;
  const imageCards = data.imageCards.edges;

  return (
    <Layout>
      <SEO title={`${tag} - wishes and e-cards`} description={`Wishes and E-Cards of ${tag}`} />

      <TagPostsWrapper>
        <TagPageHeading>
          <TagName>#{tag}</TagName>
        </TagPageHeading>
        {!!wishes.length && (
          <>
            <SectionTitle>Wishes</SectionTitle>
            <PostCardList posts={wishes} />
          </>
        )}
        {!!imageCards.length && (
          <>
            <SectionTitle>E-Cards</SectionTitle>
            <ImageCardList posts={imageCards} />
          </>
        )}
      </TagPostsWrapper>
    </Layout>
  );
};

export default Tag;

export const pageQuery = graphql`
  query($tag: String) {
    wishes: allMdx(
      limit: 2000
      sort: { fields: frontmatter___createdDate, order: DESC }
      filter: { frontmatter: { tags: { regex: $tag }, contentType: { in: ["wishes", "poems"] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            createdDate(formatString: "DD MMMM, YYYY")
            title
            slug
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 570, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    imageCards: allMdx(
      limit: 2000
      sort: { fields: frontmatter___createdDate, order: DESC }
      filter: { frontmatter: { tags: { regex: $tag }, contentType: { eq: "e-cards" } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            createdDate(formatString: "DD MMMM, YYYY")
            title
            slug
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 570, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
